import Vue from 'vue'
import App from './App.vue'
import router from './router'
import GoEasy from 'goeasy';

Vue.config.productionTip = false
import Notify from '@wcjiang/notify';
import xm2413 from './views/xm2413.mp3';
import y823 from './views/y823.mp3';
Vue.prototype.globalData = {
  currentAgent: null
};

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
Vue.prototype.$audioPrompt = true;
Vue.prototype.$titlePrompt = true;
Vue.prototype.$notify = new Notify({
  message: '有消息了。', // 标题
  effect: 'flash', // flash | scroll 闪烁还是滚动
  openurl: 'https://jingtaotao.shop', // 点击弹窗打开连接地址
  onclick: () => { // 点击弹出的窗之行事件
    console.log('---')
  },
  // 可选播放声音
  audio: {
    // 可以使用数组传多种格式的声音文件
    // file: ['msg.mp4', 'msg.mp3', 'msg.wav']
    // 下面也是可以的哦
    file: y823
  },
  // 标题闪烁，或者滚动速度
  interval: 1000,
  // 可选，默认绿底白字的  Favicon
  updateFavicon: {
    // favicon 字体颜色
    textColor: '#fff',
    // 背景颜色，设置背景颜色透明，将值设置为“transparent”
    backgroundColor: '#2F9A00'
  },
  // 可选chrome浏览器通知，默认不填写就是下面的内容
  notification: {
    title: '通知！', // 设置标题
    icon: '', // 设置图标 icon 默认为 Favicon
    body: '您来了一条新消息', // 设置消息内容
  }
});
const goEasy = GoEasy.getInstance({
  host: 'hangzhou.goeasy.io',	//应用所在的区域地址: 【hangzhou.goeasy.io |singapore.goeasy.io】
  appkey: 'BC-a2da433d261b4dc2a34e7764f6110bf2', // common key,
  modules: ['im']
});
Vue.prototype.GoEasy = GoEasy;
Vue.prototype.goEasy = goEasy;

